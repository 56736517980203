var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "othercase" }, [
    _c("div", { staticClass: "CaseContent" }, [
      _c("div", { staticClass: "title" }, [
        _c("div", [
          _c("i", {
            staticClass: "iconfont icon-back",
            on: { click: _vm.goback },
          }),
          _c("span", [_vm._v(_vm._s(this.$route.query.name))]),
        ]),
        _c("div", [
          _c("span", { on: { click: _vm.caseId } }, [_vm._v("涉他人案件")]),
        ]),
      ]),
      _c(
        "div",
        { ref: "common", staticClass: "com" },
        [
          _vm._l(_vm.mainarr, function (item, index) {
            return _c("ul", { key: index, staticClass: "company" }, [
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.decade(item.doc_id)
                    },
                  },
                },
                [_vm._v(_vm._s(item.title))]
              ),
              _c("li", [
                _c("p", [_vm._v(_vm._s(item.court.trim()))]),
                _c("p", [_vm._v("/" + _vm._s(item.case_id.trim()))]),
                _c("p", [_vm._v("/" + _vm._s(item.pub_date.trim()))]),
              ]),
            ])
          }),
          _c("loading", {
            staticStyle: { "margin-top": "20px" },
            attrs: { showflag: _vm.display },
          }),
        ],
        2
      ),
    ]),
    _vm.encounter
      ? _c(
          "div",
          { staticClass: "enclose" },
          [
            _c("precedent", {
              attrs: { id: _vm.scan },
              on: { changeflag: _vm.scandal },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }