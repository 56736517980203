<template>
  <div class="othercase">
    <div class="CaseContent">
      <div class="title">
        <div>
          <i class="iconfont icon-back" @click="goback"></i>
          <span>{{ this.$route.query.name }}</span>
        </div>
        <div>
          <span @click="caseId">涉他人案件</span>
        </div>
      </div>
      <div class="com" ref="common">
        <ul class="company" v-for="(item, index) in mainarr" :key="index">
          <li @click="decade(item.doc_id)">{{ item.title }}</li>
          <li>
            <p>{{ item.court.trim() }}</p>
            <p>/{{ item.case_id.trim() }}</p>
            <p>/{{ item.pub_date.trim() }}</p>
          </li>
        </ul>
        <loading :showflag="display" style="margin-top:20px"></loading>
      </div>
    </div>
    <div v-if="encounter" class="enclose">
      <precedent :id="scan" @changeflag="scandal"></precedent>
    </div>
  </div>
</template>
<script>
import { pydataBase } from '~api-config'
import { tools } from '@/pages/Search/compoments/tool'
import loading from '@/pages/Search/compoments/loading'
import precedent from '@/pages/precedentDetail'
export default {
  components: { loading, precedent },
  data() {
    return {
      mainarr: [],
      display: 1,
      scan: null,
      encounter: false
    }
  },
  methods: {
    decade(id) {
      this.scan = id
      this.encounter = true
    },
    scandal() {
      this.encounter = false
    },
    goback() {
      this.$router.go(-1)
    },
    caseId() {
      this.$router.push({
        path: '/currson/othercase/caseid',
        query: {
          name: this.$route.query.name
        }
      })
    }
  },
  mounted() {
    let flag = true
    let scroll = null
    scroll = tools.scroll(this.$refs.common, num => {
      if (!flag) {
        return false
      }
      flag = false
      /* ?name=${this.$route.query.name}&slug=0&offset=${num}&show=1 */
      this.$axios
        .post(`${pydataBase}/api/party/card`, {
          name: this.$route.query.name,
          slug: 0,
          offset: num,
          show: 1
        })
        .then(res => {
          if (res.data.result.length === 0) {
            this.display = 0
            scroll()
            return false
          }
          this.mainarr = this.mainarr.concat(res.data.result)
          this.mainarr.forEach(item => {})
          flag = true
        })
    })
    /* ?name=${this.$route.query.name}&slug=0&offset=1&show=1 */
    this.$axios
      .post(`${pydataBase}/api/party/card`, {
        name: this.$route.query.name,
        slug: 0,
        offset: 1,
        show: 1
      })
      .then(res => {
        this.mainarr = res.data.result
        this.mainarr.forEach(item => {})
        this.display = 2
      })
  }
}
</script>

<style lang="stylus">
.othercase
  width 100%
  height 100%
  .enclose
    width 100%
    height 100%
    position fixed
    top 0px
    left 0px
    background #ffffff
    z-index 999999
  .CaseContent
    width 100%
    position relative
    height 100%
    overflow hidden
    .com
      width 100%
      margin 0px auto
      height calc(100% - 45px)
      overflow auto
      margin-top 45px
      box-sizing border-box
      padding 0px 15px
      .company
        width 100%
        border-bottom 1px solid #F3F4F7
        overflow hidden
        margin-top 15px
        li:nth-child(1)
          font-size 15px
          color #000000
        li:nth-child(2)
          color #999999
          font-size 12px
          margin-top 5px
          overflow hidden
          margin-bottom 7px
          p:nth-child(1)
            max-width 27%
            height 20px
            white-space nowrap
            text-overflow ellipsis
            overflow hidden
            float left
          p:nth-child(2)
            max-width 50%
            height 20px
            white-space nowrap
            text-overflow ellipsis
            overflow hidden
            float left
          p:nth-child(3)
            max-width 22%
            height 20px
            white-space nowrap
            text-overflow ellipsis
            overflow hidden
            float left
    .title
      position fixed
      display flex
      height 45px
      line-height 45px
      padding-left 5px
      padding-right 5px
      box-sizing border-box
      justify-content space-between
      font-size 15px
      color #000000
      border-bottom 1px solid #F3F4F7
      z-index 99999
      background #ffffff
      width 100%
      div:nth-child(2)
        span
          color #4B9EFB
          margin-right 20px
</style>
